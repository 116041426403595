/*
 * ReviewStoreForm Messages
 *
 * This contains all the text for the ReviewStoreForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.ReviewStoreForm';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Cửa hàng Form',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Thông tin cơ bản',
  },
  storeName: {
    id: `${scope}.storeName`,
    defaultMessage: 'Tên chi nhánh',
  },
  storeAddress: {
    id: `${scope}.storeAddress`,
    defaultMessage: 'Địa chỉ chi nhánh',
  },
  createNewStore: {
    id: `${scope}.createNewStore`,
    defaultMessage: 'Tạo cửa hàng mới',
  },
  notCreateNewStore: {
    id: `${scope}.notCreateNewStore`,
    defaultMessage: 'Không tạo cửa hàng',
  },
  mergeStore: {
    id: `${scope}.mergeStore`,
    defaultMessage: 'Gọp vào cửa hàng có sẵn',
  },
});
