/*
 *
 * InvitationPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const invitation = createAsyncAction(
  ActionTypes.FETCH_INVITATION_REQUEST,
  ActionTypes.FETCH_INVITATION_SUCCESS,
  ActionTypes.FETCH_INVITATION_FAILURE,
)<void, {}, Error>();

export const checkEmailSubmit = (values: any) => action(ActionTypes.CHECK_EMAIL_SUBMIT, values);

export const checkEmail = createAsyncAction(
  ActionTypes.CHECK_EMAIL_REQUEST,
  ActionTypes.CHECK_EMAIL_SUCCESS,
  ActionTypes.CHECK_EMAIL_FAILURE,
)<void, {}, Error>();

export const signup = createAsyncAction(
  ActionTypes.SIGNUP_REQUEST,
  ActionTypes.SIGNUP_SUCCESS,
  ActionTypes.SIGNUP_FAILURE,
)<{}, {}, Error>();

export const accept = createAsyncAction(
  ActionTypes.ACCEPT_INVITATION_REQUEST,
  ActionTypes.ACCEPT_INVITATION_SUCCESS,
  ActionTypes.ACCEPT_INVITATION_FAILURE,
)<{}, {}, Error>();
