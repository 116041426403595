/*
 * InvitationReviewStores Messages
 *
 * This contains all the text for the InvitationReviewStores component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.InvitationReviewStores';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Duyệt cửa hàng',
  },
  acceptInvitation: {
    id: `${scope}.acceptInvitation`,
    defaultMessage: 'Chấp nhận lời mời',
  },
  headerLabel: {
    id: `${scope}.headerLabel`,
    defaultMessage: 'Thông tin cửa hàng',
  },
  storeImage: {
    id: `${scope}.storeImage`,
    defaultMessage: 'Hình ảnh cửa hàng',
  },
  notice1: {
    id: `${scope}.notice1`,
    defaultMessage: 'Thông tin cửa hàng là thông tin giao hàng của bạn,',
  },
  notice2: {
    id: `${scope}.notice2`,
    defaultMessage: 'vui lòng xem lại tất cả để chấp nhận lời mời.',
  },
});
