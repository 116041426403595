/**
 *
 * ReviewStoreForm
 *
 */

import React, { ForwardRefRenderFunction, useImperativeHandle } from 'react';
import styled from 'styles/styled-components';
import { Col, Input as AInput, Row, Radio, Form } from 'antd';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import utilsMessages from 'utils/messages';
import Button from 'components/TrackingComponents/Button';
import Input from 'components/TrackingComponents/Input';
import LocationFormSection from '../LocationFormSection';

const AntdInput = styled(AInput)<any>``;

const InfoContainer = styled.div`
  margin-bottom: 6pt;
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
`;

interface OwnProps {
  store: {
    name: string;
    location: {
      address: string;
      city: string;
      district: string;
    };
    telephone: string;
    review: string;
  };
  onCancel: () => void;
  onSubmit: (data: any) => void;
}

const ReviewStoreForm: ForwardRefRenderFunction<any, OwnProps> = (
  { store, onCancel: _onCancel, onSubmit: _onSubmit },
  ref,
) => {
  const [form] = Form.useForm();
  const formRef = React.useRef();
  useImperativeHandle(ref, () => formRef.current!);

  const onSubmit = (values) => {
    form.resetFields();
    _onSubmit(values);
  };

  const onCancel: React.MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    _onCancel();
  };

  const locationValue = Form.useWatch('location', form);
  return (
    <Form form={form} layout="vertical" requiredMark={false} onFinish={(values) => onSubmit(values)} size="large">
      <InfoContainer>
        <Heading>
          <FormattedMessage {...messages.basicInfo} />
        </Heading>
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...messages.storeName}>
              {(storeName) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: storeName }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name="name"
                      label={storeName}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: fieldRequired,
                        },
                      ]}
                      initialValue={store.name}
                    >
                      <Input
                        InputComponent={AntdInput}
                        trackingCategory="Edit Store Form | Input"
                        trackingAction="Enter Store name"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <LocationFormSection
          location={{
            address: locationValue?.address || store.location.address,
            city: locationValue?.city || store.location.city,
            district: locationValue?.district || store.location.district,
          }}
          formPrefix={['location']}
          form={form}
        />
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <FormattedMessage {...utilsMessages.phone}>
              {(phone) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
                  {(fieldRequired) => (
                    <Form.Item
                      name="telephone"
                      label={phone}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: fieldRequired,
                        },
                      ]}
                      initialValue={store.telephone}
                    >
                      <Input
                        InputComponent={AntdInput}
                        trackingCategory="Edit Store Form | Input"
                        trackingAction="Enter Phone"
                        type="text"
                      />
                    </Form.Item>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col lg={24} xs={24}>
            <Form.Item name="review" initialValue={store.review || 'create'}>
              <Radio.Group>
                <Radio value="create">
                  <FormattedMessage {...messages.createNewStore} />
                </Radio>
                <Radio value="notCreate">
                  <FormattedMessage {...messages.notCreateNewStore} />
                </Radio>
                {/* <Radio value="merge">
                    <FormattedMessage {...messages.mergeStore} />
                  </Radio> */}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </InfoContainer>
      <Row>
        <Col offset={8} span={8}>
          <Button
            trackingCategory="Edit Store Form | Button"
            trackingAction="Cancel"
            block
            type="secondary"
            ghost
            onClick={onCancel}
          >
            <FormattedMessage {...utilsMessages.cancel} />
          </Button>
        </Col>
        <Col span={8}>
          <Button
            trackingCategory="Edit Store Form | Button"
            trackingAction="Save"
            block
            type="primary"
            htmlType="submit"
          >
            <FormattedMessage {...utilsMessages.confirm} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default React.forwardRef(ReviewStoreForm);
