/*
 *
 * InvitationPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/InvitationPage/DEFAULT_ACTION',
  FETCH_INVITATION_REQUEST = 'app/InvitationPage/FETCH_INVITATION_REQUEST',
  FETCH_INVITATION_SUCCESS = 'app/InvitationPage/FETCH_INVITATION_SUCCESS',
  FETCH_INVITATION_FAILURE = 'app/InvitationPage/FETCH_INVITATION_FAILURE',
  CHECK_EMAIL_SUBMIT = 'app/InvitationPage/CHECK_EMAIL_SUBMIT',
  CHECK_EMAIL_REQUEST = 'app/InvitationPage/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS = 'app/InvitationPage/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'app/InvitationPage/CHECK_EMAIL_FAILURE',
  SIGNUP_REQUEST = 'app/InvitationPage/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'app/InvitationPage/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'app/InvitationPage/SIGNUP_FAILURE',
  ACCEPT_INVITATION_REQUEST = 'app/InvitationPage/ACCEPT_INVITATION_REQUEST',
  ACCEPT_INVITATION_SUCCESS = 'app/InvitationPage/ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_FAILURE = 'app/InvitationPage/ACCEPT_INVITATION_FAILURE',
}

export default ActionTypes;
