/*
 *
 * InvitationPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  invitation: {
    loading: true,
  },
  isCheckingEmail: false,
  validationEmailMessage: '',
  accpectLoading: false,
  signup: null,
};

function invitationPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_INVITATION_SUCCESS:
      return {
        ...state,
        invitation: action.payload,
      };
    case ActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        signup: action.payload,
      };
    case ActionTypes.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        isCheckingEmail: true,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: '',
      };
    case ActionTypes.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        isCheckingEmail: false,
        validationEmailMessage: action.payload,
      };
    case ActionTypes.ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
        accpectLoading: true,
      };
    case ActionTypes.ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        accpectLoading: false,
      };
    default:
      return state;
  }
}

export default invitationPageReducer;
