/*
 * InvitationPage Messages
 *
 * This contains all the text for the InvitationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.InvitationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Mời khách hàng',
  },
  updateStoresFailed: {
    id: `${scope}.updateStoresFailed`,
    defaultMessage: 'Cập nhật cửa hàng thất bại',
  },
  acceptInvitationSuccessfully: {
    id: `${scope}.acceptInvitationSuccessfully`,
    defaultMessage: 'Chấp nhận lời mời thành công',
  },
  acceptInvitationFailed: {
    id: `${scope}.acceptInvitationFailed`,
    defaultMessage: 'Chấp nhận lời mời thất bại',
  },
});
