/**
 *
 * InvitationGuest
 *
 */

import * as React from 'react';

import { Col, Row, Skeleton } from 'antd';

import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';
import PageHeader from 'components/PageHeader/PageHeader';
import media from 'utils/mediaStyle';
import messages from './messages';
import styled from 'styles/styled-components';
import topBg from './top-bg.png';
import utilsMessages from 'utils/messages';

interface OwnProps {
  gotoSigninPage: () => void;
  gotoSignupPage: () => void;
  gotoReviewStores: () => void;
  onAcceptInvitation: (data) => void;
  isLoggedIn: boolean;
  invitation: any;
}

const Container = styled.section`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  text-align: center;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;

const CompanyInfo = styled.div`
  margin-top: 24px;
  margin-bottom: 36px;
`;

const CompanyName = styled.h3`
  font-weight: bold;
  font-size: 16px;
`;

const CompanyAddress = styled.p`
  color: #8c8c8c;
`;

const InvitationWelcome: React.SFC<OwnProps> = (props: OwnProps) => {
  const { invitation } = props;
  return (
    <Container>
      <PageHeader size="large" center>
        <FormattedMessage {...messages.header} />
      </PageHeader>
      {invitation.loading ? (
        <Skeleton />
      ) : (
        <>
          <p>
            <FormattedMessage {...messages.inviteMessage} />
            &#32;
            <b>{invitation.supplier.name}</b>:
          </p>
          <CompanyInfo>
            <CompanyName>{invitation.name}</CompanyName>
            <CompanyAddress>
              {invitation.location.address}, {invitation.location.district}, {invitation.location.city}
            </CompanyAddress>
          </CompanyInfo>

          {props.isLoggedIn ? (
            <div>
              {invitation.stores.length ? (
                <Row justify="center">
                  <Button type="primary" onClick={props.gotoReviewStores}>
                    <FormattedMessage {...messages.reviewInvitation} />
                  </Button>
                </Row>
              ) : (
                <Row justify="center">
                  <Button type="primary" onClick={() => props.onAcceptInvitation(invitation.stores)}>
                    <FormattedMessage {...messages.acceptInvitation} />
                  </Button>
                </Row>
              )}
              {/* <p>
                <FormattedMessage {...messages.not} />
                &#32;
                <b>{invitation.name}</b>
              </p> */}
            </div>
          ) : (
            <>
              <Row gutter={24} justify="center">
                <Col span="12">
                  <p>
                    <FormattedMessage {...messages.alreadyAccount} />
                  </p>
                </Col>
                <Col span="12">
                  <p>
                    <FormattedMessage {...messages.noAccount} />
                  </p>
                </Col>
              </Row>
              <Row gutter={24} justify="center">
                <Col span="12">
                  <Button block type="primary" onClick={props.gotoSigninPage}>
                    <FormattedMessage {...utilsMessages.signin} />
                  </Button>
                </Col>
                <Col span="12">
                  <Button block type="primary" ghost onClick={props.gotoSignupPage}>
                    <FormattedMessage {...utilsMessages.signup} />
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default InvitationWelcome;
