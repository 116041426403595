/*
 * InvitationGuest Messages
 *
 * This contains all the text for the InvitationGuest component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.InvitationWelcome';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chào mừng đến với Kamereo!',
  },
  inviteMessage: {
    id: `${scope}.inviteMessage`,
    defaultMessage: 'Bạn nhận được lời mời sử dụng hệ thống từ',
  },
  alreadyAccount: {
    id: `${scope}.alreadyAccount`,
    defaultMessage: 'Bạn đã có tài khoản?',
  },
  noAccount: {
    id: `${scope}.noAccount`,
    defaultMessage: 'Bạn chưa có tài khoản?',
  },
  reviewInvitation: {
    id: `${scope}.reviewInvitation`,
    defaultMessage: 'Kiểm tra thông tin cửa hàng',
  },
  acceptInvitation: {
    id: `${scope}.acceptInvitation`,
    defaultMessage: 'Chấp nhận lơi mời',
  },
  not: {
    id: `${scope}.not`,
    defaultMessage: 'Không phải',
  },
});
