import {
  acceptAsNewBuyerInvitation,
  acceptInvitation,
  checkValidEmail,
  getInvitation,
  setInvitationStores,
  getBuyer,
} from 'utils/apollo';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { checkEmail as checkEmailActions, invitation as invitationActions, signup as signupActions } from './actions';
import { createMatchSelector, push } from 'connected-react-router';

import ActionTypes from './constants';
import { message } from 'antd';
import messages from './messages';
import selectInvitationPage from './selectors';
import translations from 'translations';

function* signupFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SIGNUP_REQUEST);
    payload.lang = localStorage.getItem('lang') || 'vi';
    delete payload.confirmPassword;
    yield put(signupActions.success(payload));
    const route = yield select(createMatchSelector('/invitations/:slug'));

    yield put(push(`/invitations/${route.params.slug}/welcome`));
  }
}

function* checkEmailFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CHECK_EMAIL_SUBMIT);
    yield put(checkEmailActions.request());
    const response = yield call(checkValidEmail, {
      email: payload,
    });
    if (!response.errors) {
      yield put(checkEmailActions.success(response));
    } else {
      yield put(checkEmailActions.failure(response.errors[0].message));
    }
  }
}

function* initData() {
  const route = yield select(createMatchSelector('/invitations/:slug'));
  const response = yield call(getInvitation, {
    id: route.params.slug,
  });
  if (response.errors) {
    yield put(invitationActions.failure(response.errors));
    yield put(push('/'));
  } else {
    yield put(invitationActions.success(response));
  }
}

function* acceptFlow() {
  while (true) {
    const { payload: stores } = yield take(ActionTypes.ACCEPT_INVITATION_REQUEST);

    const loggedIn = !!localStorage.getItem('token');
    const { signup, invitation } = yield select(selectInvitationPage());
    if (stores) {
      const setStoresResponse = yield call(setInvitationStores, {
        input: {
          invitationId: invitation.id,
          stores: stores,
        },
      });
      if (setStoresResponse.errors) {
        message.error(translations(messages.updateStoresFailed));
        return;
      }
    }
    if (loggedIn) {
      const buyer = yield call(getBuyer);
      const acceptResponse = yield call(acceptInvitation, {
        invitationId: invitation.id,
        buyerId: +buyer.id,
      });
      if (acceptResponse.errors) {
        message.error(translations(messages.acceptInvitationFailed));
      } else {
        message.success(translations(messages.acceptInvitationSuccessfully));
        yield put(push('/'));
      }
    } else if (signup) {
      const acceptByNewResponse = yield call(acceptAsNewBuyerInvitation, {
        input: {
          invitationId: invitation.id,
          ...signup,
          location: invitation.location,
        },
      });
      if (acceptByNewResponse.errors) {
        message.error(translations(messages.acceptInvitationFailed));
      } else {
        message.success(translations(messages.acceptInvitationSuccessfully));
        yield put(push('/signin'));
      }
    }
  }
}

// Individual exports for testing
export default function* invitationPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initData);
  yield fork(signupFlow);
  yield fork(acceptFlow);
  yield fork(checkEmailFlow);
}
