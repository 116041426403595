/**
 *
 * InvitationReviewStores
 *
 */

import { Col, Row, Skeleton } from 'antd';
import React, { useState, useEffect } from 'react';

import { Avatar } from 'components/Company/Avatar';
import Button from 'components/Button/Button';
import Edit from './Edit.svg';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal/Modal';
import PageHeader from 'components/PageHeader/PageHeader';
import ReviewStoreForm from 'components/ReviewStoreForm';
import StoreList from 'components/StoreList';
import bottomBg from './bottom-bg.png';
import media from 'utils/mediaStyle';
import messages from './messages';
import styled from 'styles/styled-components';
import translations from 'translations';

const Container = styled.section`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  background-image: url(${bottomBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10px 20px;
  margin-bottom: 8pt;
  ${media.md`
    padding: 10pt 20pt;
  `};
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
  text-transform: uppercase;
`;
const NoticeBlock = styled.div`
  margin-bottom: 24px;
`;

const Notice = styled.p`
  text-align: center;
  font-size: 14px;
`;

interface OwnProps {
  invitation: any;
  onAcceptInvitation: (stores: any) => void;
}

const InvitationReviewStores: React.SFC<OwnProps> = (props: OwnProps) => {
  const { invitation } = props;
  const [isShowReviewStoreModal, setShowReviewStoreModal] = useState(0);
  const [canAcceptInvitation, setCanAcceptInvitation] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const [storeList, setStoreList] = useState([] as any);

  useEffect(() => {
    setStoreList(invitation.stores);
  }, [invitation]);
  const onSubmitStore = (store) => {
    storeList[isShowReviewStoreModal - 1] = {
      ...store,
      imageUrl: imageUrl,
    };
    const reviewedList = storeList.filter(({ review }) => !!review);
    setCanAcceptInvitation(reviewedList.length !== storeList.length);
    setShowReviewStoreModal(0);
    setStoreList(storeList);
  };

  const onAcceptInvitation = () => {
    const stores: any[] = [];
    storeList.forEach((store) => {
      if (store.review === 'create') {
        delete store.review;
        stores.push(store);
      }
    });
    props.onAcceptInvitation(stores);
  };

  return (
    <Container>
      <PageHeader size="large" center>
        <FormattedMessage {...messages.header} />
      </PageHeader>
      {invitation.loading ? (
        <Skeleton />
      ) : (
        <>
          <NoticeBlock>
            <Notice>
              <FormattedMessage {...messages.notice1} />
            </Notice>
            <Notice>
              <FormattedMessage {...messages.notice2} />
            </Notice>
          </NoticeBlock>
          <StoreList
            stores={storeList || []}
            review
            onShowReviewModal={(index) => setShowReviewStoreModal(index + 1)}
          />
          <Row justify="end">
            <Button type="primary" disabled={canAcceptInvitation} onClick={onAcceptInvitation}>
              <FormattedMessage {...messages.acceptInvitation} />
            </Button>
          </Row>
          <Modal
            size="large"
            headerLabel={translations(messages.headerLabel)}
            headerIcon={Edit}
            isOpen={!!isShowReviewStoreModal}
          >
            <Row gutter={8}>
              <Col lg={8} xs={24}>
                <Heading>{translations(messages.storeImage)}</Heading>
                <Avatar src={imageUrl} onUploadImageSuccess={setImageUrl} />
              </Col>
              <Col lg={16} xs={24}>
                <ReviewStoreForm
                  store={(storeList || [])[isShowReviewStoreModal - 1] || {}}
                  onCancel={() => setShowReviewStoreModal(0)}
                  onSubmit={onSubmitStore}
                />
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default InvitationReviewStores;
