import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the invitationPage state domain
 */

const selectInvitationPageDomain = (state: ApplicationRootState) => {
  return state ? state.invitationPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by InvitationPage
 */

const selectInvitationPage = () =>
  createSelector(selectInvitationPageDomain, substate => {
    return substate;
  });

export default selectInvitationPage;
export { selectInvitationPageDomain };
