/**
 *
 * InvitationPage
 *
 */

import * as React from 'react';

import { ContainerState, RootState } from './types';
import { Dispatch, compose } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { accept as acceptActions, checkEmailSubmit, signup as signupActions } from './actions';

import InvitationReviewStores from 'components/InvitationReviewStores';
import InvitationWelcome from 'components/InvitationWelcome';
import SignupForm from 'components/SignupForm/SignupForm';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectInvitationPage from './selectors';
import styled from 'styled-components';

interface OwnProps {
  invitationpage: ContainerState;
  match: any;
  route: any;
}

interface StateProps {}

interface DispatchProps {
  gotoSigninPage: () => void;
  gotoSignupPage: () => void;
  gotoReviewStores: () => void;
  onSubmitSignup: (data: any) => void;
  onCheckEmail: (data: any) => void;
  onAcceptInvitation: (data: any) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvitationWrapper = styled.div`
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;

export class InvitationPage extends React.PureComponent<Props> {
  public render() {
    const loggedIn = !!localStorage.getItem('token');
    const { match } = this.props;
    const { isCheckingEmail, validationEmailMessage, signup, invitation, accpectLoading } = this.props.invitationpage;
    return (
      <Switch>
        <Route
          path={`${match.path}/welcome`}
          render={(props: any) => (
            <InvitationWrapper>
              <Spin spinning={accpectLoading}>
                <InvitationWelcome
                  {...props}
                  invitation={invitation}
                  gotoSigninPage={this.props.gotoSigninPage}
                  gotoSignupPage={this.props.gotoSignupPage}
                  gotoReviewStores={this.props.gotoReviewStores}
                  onAcceptInvitation={this.props.onAcceptInvitation}
                  isLoggedIn={signup || loggedIn}
                />
              </Spin>
            </InvitationWrapper>
          )}
        />
        <Route
          path={`${match.path}/signup`}
          render={(props: any) => {
            if (!loggedIn) {
              return (
                <Container>
                  <SignupForm
                    {...props}
                    companyName={invitation.name}
                    telephone={invitation.telephone}
                    onSubmit={this.props.onSubmitSignup}
                    onCheckEmail={this.props.onCheckEmail}
                    isCheckingEmail={isCheckingEmail}
                    validationEmailMessage={validationEmailMessage}
                    signinUrl={`/signin?redirect=/invitations/${match.params.slug}`}
                  />
                </Container>
              );
            }
            return <Redirect to={`${match.url}/welcome`} />;
          }}
        />
        <Route
          path={`${match.path}/review-stores`}
          render={(props: any) => {
            if (signup || loggedIn) {
              return (
                <InvitationWrapper>
                  <Spin spinning={accpectLoading}>
                    <InvitationReviewStores
                      {...props}
                      invitation={invitation}
                      onAcceptInvitation={this.props.onAcceptInvitation}
                    />
                  </Spin>
                </InvitationWrapper>
              );
            }
            return <Redirect to={`${match.url}/welcome`} />;
          }}
        />
        <Redirect to={`${match.url}/welcome`} />
      </Switch>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  invitationpage: selectInvitationPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    gotoSigninPage: () => dispatch(push(`/signin?redirect=${window.location.pathname}`)),
    gotoSignupPage: () => dispatch(push('signup')),
    gotoReviewStores: () => dispatch(push('review-stores')),
    onSubmitSignup: (data: any) => dispatch(signupActions.request(data)),
    onCheckEmail: (data: any) => dispatch(checkEmailSubmit(data)),
    onAcceptInvitation: (data: any) => dispatch(acceptActions.request(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({ key: 'invitationPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'invitationPage', saga: saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(InvitationPage);
